import * as React from "react"
import { graphql,navigate } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"

const FormTest = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="お問い合わせ｜株式会社スタートアッププラス" />

      <div className="block xl:flex justify-between">

        <div id="pageTitle" className="relative xl:fixed w-full xl:w-48 h-auto xl:h-full text-white bg-mail bg-no-repeat bg-center bg-cover z-20 ">
          <h1 className="font-Copperplate text-center text-2xl sm:text-3xl pt-10 pb-20">Contact Us</h1>
        </div>

        <div id="main" className="static xl:ml-48 w-full">
          <div className=" w-11/12 max-w-[800px] mx-auto py-10 sm:py-10">

            <section id="gaiyou" className="mb-20">
              <h2 className="text-2xl border-b border-key-sand mb-10 py-3 ">
                お問い合わせ
              </h2>
              <form onSubmit={ () => {navigate("/form_sent")}} action="https://docs.google.com/forms/u/0/d/e/1FAIpQLScGcnYS6xdYTTa0QOZXaZIwJqLkDXWHEE4OleN_FdDU3jIdpA/formResponse" target="hidden_iframe">
                <h2>サンプルフォーム</h2>
                <div>
                    <label htmlFor="name">名前</label>
                    <input id="name" type="text" name="entry.1575271562" placeholder="名前" required></input>
                    <p class="error">
                      <span class="invalid">名前を入力して下さい</span>
                    </p>
                </div>
                <div>
                    <label htmlFor="email">メールアドレス</label>
                    <input id="email" type="text" name="entry.726732867" placeholder="email@example.com" pattern="[\w\d_-]+@[\w\d_-]+\.[\w\d._-]+" required></input>
                    <p class="error">
                      <span class="invalid">正しい電話番号の形式で入力して下さい</span>
                    </p>
                </div>
                <button type="submit">送信</button>
              </form>
              <iframe title="hidden_iframe" name="hidden_iframe" style={{display:'none'}}></iframe>

            </section>

          </div>
        </div>
      </div>

    </Layout>
  )
}

export default FormTest

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`